import Image from "next/image"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"
import { Grid, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"
import Container from "@components/v2/Container"

import ArrowCircleLeft from "../images/arrow-circle-left.svg"
import ArrowCircleRight from "../images/arrow-circle-right.svg"

import "swiper/css"
import "swiper/css/pagination"

const StyledContainer = tw(Container)`
    reku-new  
    xl:py-[80px] py-8 xl:px-5 px-6
`

const Title = tw.div`text-main xl:heading-3 heading-6 `
const Card = tw.div`xl:h-full xl:w-full  flex flex-col gap-3 xl:mb-0 mb-3 `

const ArticleTitle = tw.div`xl:button-1 title-4 line-clamp-4`

const ImageWrapper = tw.div`
    w-full relative
    rounded-xs
    overflow-hidden
    aspect-[2/1]
`

const StyledImage = styled(Image)`
    border-radius: 0.25rem;
    overflow: hidden;
    object-fit: cover;
`

const SwiperWrapper = styled.div`
    ${tw`mt-3 relative `}
    .swiper-pagination {
        bottom: -20px;
    }

    .swiper-slide {
        ${tw`gap-3`}
    }
`

const PaginationWrapper = styled.div`
    ${tw`flex justify-center items-center mt-6`}

    .swiper-pagination-bullet {
        ${tw`h-1.5`}
        ${tw`transition-all duration-300`}
        ${tw`bg-[#6BC4C7] opacity-100`}
        &-active {
            ${tw`w-4`}
            ${tw`rounded`}
            ${tw`bg-[#1BA7AC]`}
        }
    }
`

const Contents = [
    {
        url: "https://reku.id/information/transparansi-jaminan-aman-untuk-transaksimu",
        title: "Jaminan Aman untuk Transaksimu",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F05%2FImage-feature-template-jaminan-aman-untuk-transaksi-01.webp&w=1920&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-perjalanan-6-tahun-reku-hingga-saat-ini",
        title: "Perjalanan 6 Tahun Reku Hingga Saat Ini!",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F02%2FImage-feature-template-perjalanan-6-tahun-reku-01-1-scaled.webp&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-forum-transparansi-apakah-reku-akan-membuat-token-sendiri",
        title: "Forum Transparansi: Apakah Reku Akan Membuat Token Sendiri?",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F02%2FForum-Transparansi-Apakah-Reku-Akan-Membuat-Token-Sendiri.png&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-memahami-crypto-lebih-menyenangkan-di-fitur-rekuiz",
        title: "Memahami Crypto Lebih Menyenangkan di Fitur Rekuiz ",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F01%2FFeatured-Image-Article-for-Transparency-Microsite-Opsi-1-Pahami-Crypto-Lebih-Menyenangkan-di-Rekuiz-scaled.webp&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-trading-competition-momen-asah-kemampuan-dan-tingkatkan-profit",
        title: "Trading Competition, Momen Asah Kemampuan dan Tingkatkan Profit",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F01%2FImage-feature-template-portal-transparansi-trading-competition-01.webp&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-bijak-memilih-informasi-hanya-di-reku-official",
        title: "Bijak Memilih Informasi, Hanya di Reku Official",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F01%2FArticle-Banner-Article-Microsite-2024-scaled.webp&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/transparansi-investor-personality-untuk-temukan-investasi-yang-tepat",
        title: "Investor Personality untuk Temukan Investasi yang Tepat",
        image: "https://reku.id/_next/image?url=https%3A%2F%2Fapi-blog.reku.id%2Fwp-content%2Fuploads%2F2024%2F01%2FImage-feature-template-portal-transparansi-01.webp&w=3840&q=75"
    },
    {
        url: "https://reku.id/information/mengenal-tim-hebat-di-balik-reku",
        title: "Mengenal Tim Hebat di Balik Reku",
        image: "https://api-blog.reku.id/wp-content/uploads/2024/01/Artikel-10.webp"
    },
    {
        url: "https://reku.id/information/reku-jadi-yang-pertama-gabung-cfx",
        title: "Reku Jadi yang Pertama Gabung Bursa Kripto!",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Image-feature-Reku-Bursa-Komiditi-Nusantara-01.webp"
    },
    {
        url: "https://reku.id/information/telah-kantongi-sertifikasi-iso-reku-pastikan-transaksi-aman-dan-terpercaya",
        title: "Telah Kantongi Sertifikasi ISO, Reku Pastikan Transaksi Aman dan Terpercaya ",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-2.webp"
    },
    {
        url: "https://reku.id/information/staking-aman-dan-berpeluang-menguntungkan-di-stake-di-blockchain-dengan-apy-hingga-12-5",
        title: "Staking Aman dan Berpeluang Menguntungkan: Di-Stake di Blockchain dengan APY hingga 12.5%! ",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-4.webp"
    },
    {
        url: "https://reku.id/information/prioritaskan-keamanan-dan-transparansi-reku-aktif-kolaborasi-dengan-bappebti-dan-asosiasi",
        title: "Prioritaskan Keamanan dan Transparansi, Reku Aktif Kolaborasi dengan Bappebti dan Asosiasi",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-1.webp"
    },
    {
        url: "https://reku.id/information/rutin-lakukan-audit-untuk-sebagai-bukti-transparansi-dan-jaga-kepercayaan-pengguna",
        title: "Rutin Lakukan Audit Untuk Sebagai Bukti Transparansi dan Jaga Kepercayaan Pengguna",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-3-scaled.webp"
    },
    {
        url: "https://reku.id/information/jadi-pelopor-mode-lightning-dan-pro-reku-efisiensi-transaksi-untuk-user",
        title: "Jadi Pelopor Mode Lightning dan Pro, Reku Efisiensi Transaksi untuk User",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-8-scaled.webp"
    },
    {
        url: "https://reku.id/information/transaksi-di-reku-aman-dan-terpercaya",
        title: "Transaksi di Reku Aman dan Terpercaya",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-6-scaled.webp"
    },
    {
        url: "https://reku.id/information/pastikan-keamanan-dan-kelayakan-simak-proses-seleksi-ketat-reku-dalam-listing-koin",
        title: "Pastikan Keamanan dan Kelayakan, Simak Proses Seleksi Ketat Reku dalam Listing Koin",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-7-scaled.webp"
    },
    {
        url: "https://reku.id/information/lebih-bijak-kelola-aset-dengan-fitur-investment-insight",
        title: "Lebih Bijak Kelola Aset dengan Fitur Investment Insight",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-9-scaled.webp"
    },
    {
        url: "https://reku.id/information/layanan-bantuan-24-7-siap-bantu-sobat-reku-dimanapun-dan-kapanpun",
        title: "Layanan Bantuan 24/7, Siap Bantu Sobat Reku Dimanapun dan Kapanpun!",
        image: "https://api-blog.reku.id/wp-content/uploads/2023/12/Artikel-13-scaled.webp"
    }
]

const Articles = () => {
    const isDesktop = useMediaQuery({ minWidth: 1280 })

    const slides = isDesktop ? 3 : 2
    return (
        <section>
            <StyledContainer>
                <Title>
                    Temukan berbagai informasi seputar perkembangan{" "}
                    <span className='text-[#1BA7AC]'>industri aset kripto di sini.</span>
                </Title>
                <SwiperWrapper>
                    <ArrowCircleLeft
                        tw='absolute translate-y-[-50%] top-[45%] z-[99] -left-6 xl:-left-12 mr-1 xl:mr-4 cursor-pointer w-6 h-6 xl:(w-8 h-8)'
                        className={classNames("reku-article-prev", {
                            "!hidden": !isDesktop && Contents.length < 5
                        })}
                    />
                    <Swiper
                        className='xl:h-full h-[400px]'
                        slidesPerView={slides}
                        grid={{ rows: isDesktop ? 1 : 2 }}
                        pagination={{
                            clickable: true,
                            el: ".reku-article-pagination"
                        }}
                        modules={[Pagination, Navigation, Grid]}
                        spaceBetween={20}
                        navigation={{
                            nextEl: ".reku-article-next",
                            prevEl: ".reku-article-prev"
                        }}
                    >
                        {Contents.map((content) => (
                            <SwiperSlide>
                                <Card>
                                    <Link href={content.url}>
                                        <ImageWrapper>
                                            <StyledImage src={content.image} alt='reku-article' layout='fill' />
                                        </ImageWrapper>
                                    </Link>
                                    <Link href={content.url}>
                                        <ArticleTitle>{content.title}</ArticleTitle>
                                    </Link>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <PaginationWrapper className='reku-article-pagination' />
                    <ArrowCircleRight
                        tw='absolute top-[45%] translate-y-[-50%]  xl:-right-12 z-[99] -right-6 ml-1 xl:ml-4 cursor-pointer w-6 h-6 xl:(w-8 h-8)'
                        className={classNames("reku-article-next", {
                            "!hidden": !isDesktop && Contents.length < 5
                        })}
                    />
                </SwiperWrapper>
            </StyledContainer>
        </section>
    )
}

export default Articles
