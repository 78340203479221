import React from "react"
import Image from "next/image"
import tw from "twin.macro"

import Container from "@components/v2/Container"

import CommitmentImage from "../images/pillar-commitment.svg"
import FeatureImage from "../images/pillar-feature.svg"
import GradientLight from "../images/pillar-gradient-light.png"
import RegulationImage from "../images/pillar-regulation.svg"

const Title = tw.h3`
    text-[#41403E]
    text-[20px] font-bold leading-7
    xl:(text-[32px] font-bold leading-10 tracking-[-0.8px])

`

const Description = tw.span`
    text-[#737271] text-sm font-medium leading-5
`

interface CardProps {
    image: string
    title: string
    description: string
}

const Card: React.FC<CardProps> = ({ image: Icon, title, description }) => (
    <div tw='relative'>
        <div tw='w-full h-full rounded-xl bg-[#F9F8F6] border border-[#0000001A] p-3 xl:p-6 flex flex-col gap-3'>
            <div tw='flex justify-center'>
                <Icon tw='relative !w-[128px] !h-[128px] xl:(!w-[256px] !h-[256px])' />
            </div>
            <div tw='flex flex-col gap-1'>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </div>
        </div>
        <div tw='absolute w-full h-4 -bottom-1 left-[50%] transform -translate-x-[50%] -z-[1]'>
            <Image src={GradientLight.src} layout='fill' alt='Pillar Gradient Light' />
        </div>
    </div>
)

const Pillar = () => {
    return (
        <Container tw='py-8 xl:py-20'>
            <div tw='flex flex-col gap-8 xl:gap-9'>
                <div tw='flex flex-col gap-3 xl:gap-0'>
                    <Title>
                        Tiga Fondasi <span tw='text-[#1BA7AC]'>Reku</span>
                    </Title>
                </div>
                <div tw='grid xl:grid-cols-3 gap-3 xl:flex-row'>
                    <Card
                        title='Fitur dan Layanan'
                        image={FeatureImage}
                        description='Melalui proses >10 tahapan, Reku berkomitmen untuk selalu menghasilkan fitur yang sesuai dengan kebutuhan investor.'
                    />
                    <Card
                        title='Kepatuhan Regulasi'
                        image={RegulationImage}
                        description='Konsisten patuh pada perizinan, Reku juga aktif berkolaborasi dengan berbagai pihak untuk memenuhi kebutuhan investor & mendukung kemajuan industri crypto.'
                    />
                    <Card
                        title='Komitmen Tim'
                        image={CommitmentImage}
                        description='Didirikan oleh pakar investasi berpengalaman lebih dari 10 tahun bersama dengan tim yang solid, Reku siap mendampingi perjalanan #BijakBerinvestasi-mu.'
                    />
                </div>
            </div>
        </Container>
    )
}

export default Pillar
