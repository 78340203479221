import Head from "@components/Head"

import Articles from "./components/Articles"
import CTASection from "./components/CTASection"
import Forum from "./components/Forum"
import FraudulentCheck from "./components/FraudulentCheck"
import Hero from "./components/Hero"
import OfficialPartners from "./components/OfficialPartners"
import Pillar from "./components/Pillar"
import Videos from "./components/Videos"

const JesseLetter = () => (
    <div className='reku-new'>
        <Head
            title='Investasi Kripto dengan Aman & Transparan di Reku'
            description='Jelajahi dunia aset kripto dengan Reku, platform inovatif yang menawarkan transparansi dan kepatuhan regulasi. Mulai investasi tanpa ragu di sini.'
            titleSuffix=''
        />
        <Hero />
        <FraudulentCheck />
        <Videos />
        <Articles />
        <Pillar />
        <Forum />
        <CTASection />
        <OfficialPartners />
    </div>
)

export default JesseLetter
