import React from "react"
import Image from "next/image"
import { useMediaQuery } from "react-responsive"
import tw, { styled } from "twin.macro"

import Container from "@components/v2/Container"

import ACV from "../images/acv.webp"
import Coinbase from "../images/Coinbase.webp"
import Fireblocks from "../images/fireblocks.webp"
import Skystar from "../images/skystar.webp"
import Xendit from "../images/xendit.webp"

const StyledContainer = tw(Container)`
    xl:py-[80px] py-8 xl:gap-[56px] gap-6 flex flex-col
`

const Title = styled.h3`
    ${tw`text-bold dark:text-dark-bold xl:heading-3 heading-6`}
    span {
        ${tw`text-primary dark:text-dark-primary`}
    }
`

const Subtitle = tw.span`
    text-additional dark:text-dark-additional xl:title-1 title-3
`

const PartnerWrapper = tw.div`flex flex-col gap-6 text-center `
const IconWrapper = tw.div`justify-center  flex xl:flex-row flex-col xl:gap-[108px] gap-4 xl:pb-0 pb-4 items-center`

const OfficialPartners = () => {
    const isMobile = useMediaQuery({ maxWidth: 1279 })
    return (
        <section>
            <StyledContainer>
                <Title className='flex justify-center'>
                    Supporting
                    <span>&nbsp;Partners</span>
                </Title>
                <PartnerWrapper>
                    <Subtitle>Investor</Subtitle>
                    <IconWrapper>
                        <Image
                            src={Coinbase}
                            height={isMobile ? 24 : 46.5}
                            width={isMobile ? 158 : 307}
                            alt='coinbase-logo'
                        />
                        <Image
                            src={Skystar}
                            height={isMobile ? 42 : 80}
                            width={isMobile ? 86 : 165.4}
                            alt='skystar-logo'
                        />
                        <Image
                            src={ACV}
                            height={isMobile ? 28 : 46.5}
                            width={isMobile ? 164 : 273.5}
                            alt='ac-ventures-logo'
                        />
                    </IconWrapper>
                </PartnerWrapper>
                <PartnerWrapper>
                    <Subtitle>Tech Partners</Subtitle>
                    <IconWrapper>
                        <Image src={Xendit} width={isMobile ? 92 : 172} height={isMobile ? 40 : 74} alt='xendit-logo' />
                        <Image
                            src={Fireblocks}
                            width={isMobile ? 145 : 265}
                            height={isMobile ? 22 : 40}
                            alt='fireblocks-logo'
                        />
                    </IconWrapper>
                </PartnerWrapper>
            </StyledContainer>
        </section>
    )
}

export default OfficialPartners
