import { useState } from "react"
import { Trans, useTranslation } from "next-i18next"
import tw, { styled } from "twin.macro"

import Container from "@components/v2/Container"
import MobileStoreButton from "@components/v2/MobileStoreButton"
import Space from "@layouts/Space"

import CTAIllustration from "./images/cta-ilustration.svg"

const Box = tw.div`
    reku-new
    h-[420px] md:h-auto
    relative overflow-hidden
    bg-teal-500 dark:bg-dark-teal-400
    rounded-3xl

    px-4 py-8 pb-0
    md:px-[120px] md:py-[72px]
`

const Content = tw.div`
    text-center md:text-left
    md:w-fit md:ml-auto 
`

const StyledBgEllipse = tw.div`
    absolute 
    -bottom-4 md:bottom-[19px] 
    left-1/2 md:left-[157px]
    -translate-x-1/2 md:translate-x-0
    h-[240px] w-[240px]
    bg-purple-500 rounded-full
    blur-[118px]
`

interface IlustrationProps {
    isHover: boolean
}
const IlustrationWrapper = styled.div<IlustrationProps>`
    ${tw`
        absolute
        -bottom-[100px] md:-bottom-[150px] 
        left-1/2 md:left-[128px]
        -translate-x-1/2 md:translate-x-0
        z-[1]
        duration-700 ease-in-out
    `}

    ${({ isHover }) => isHover && tw`-bottom-[50px] lg:bottom-0`}
`

const StyledSpace = styled(Space)`
    align-items: center;

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 45px;
        padding-inline: 14px;
        gap: 16px;

        a {
            width: 100%;
            height: 36px;
        }
    }
`

const Title = tw.h3`
    heading-6 xl:heading-3
    text-color-bg dark:text-dark-color-bg
    mb-6 xl:mb-8

`

interface CTAProps {
    containerClassName?: string
}

const CTA: React.FC<CTAProps> = ({ containerClassName }) => {
    const { t } = useTranslation()
    const [isHover, setIsHover] = useState(false)

    return (
        <section>
            <Container className={containerClassName}>
                <Box onMouseEnter={() => setIsHover(true)}>
                    <Content>
                        <Title>
                            <Trans i18nKey='components:cta.ready_to_invest'>
                                {t("components:cta.ready_to_invest")}
                            </Trans>
                        </Title>
                        <StyledSpace>
                            <MobileStoreButton height={56} width={200} store='android' />
                            <MobileStoreButton height={56} width={200} store='ios' />
                        </StyledSpace>
                    </Content>

                    <StyledBgEllipse />
                    <IlustrationWrapper isHover={isHover}>
                        <CTAIllustration />
                    </IlustrationWrapper>
                </Box>
            </Container>
        </section>
    )
}

export default CTA
