import tw from "twin.macro"

import CTA from "@components/v2/CTA"

const CTAWrapper = tw.div`xl:py-[80px]`

const CTASection = () => {
    return (
        <CTAWrapper>
            <CTA />
        </CTAWrapper>
    )
}

export default CTASection
