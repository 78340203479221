import { useMemo } from "react"
import NextHead from "next/head"
import { useRouter } from "next/router"

import { BRAND_LOGO_URL, PUBLIC_URL } from "@config/config"
import { defaultLanguage, languages } from "@config/i18n"

const siteURL = `${PUBLIC_URL || "https://reku.id"}`

interface HeadProps extends React.PropsWithChildren<{}> {
    title?: string
    titleSuffix?: string
    description?: string
    image?: string
}

const Head: React.FC<HeadProps> = ({
    title: titleProp,
    titleSuffix,
    description: descriptionProp,
    children,
    image
}: HeadProps) => {
    const router = useRouter()

    const title = useMemo(() => {
        if (typeof titleSuffix !== "undefined") {
            return `${titleProp} ${titleSuffix}`
        }

        return `${titleProp} - Reku`
    }, [titleProp, titleSuffix])

    const description = useMemo(() => {
        return descriptionProp
    }, [descriptionProp])

    const renderedHrefLang = languages.map((lang) => {
        const subPath = lang.code !== defaultLanguage ? `/${lang.code}` : ""
        const href = `${siteURL}${subPath}${router.asPath}`

        return <link key={lang.code} rel='alternate' href={href} hrefLang={lang.code} />
    })

    return (
        <NextHead>
            <meta
                name='viewport'
                property='viewport'
                content='width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, minimum-scale=1, user-scalable=no'
            />

            <meta name='description' property='description' content={description} key='description' />

            <meta name='og:type' property='og:type' content='website' key='og:type' />
            <meta name='og:site_name' property='og:site_name' content='Reku' key='og:site_name' />
            <meta name='og:image' property='og:image' content={image} key='og:image' />
            <meta name='og:title' property='og:title' content={title} key='og:title' />
            <meta name='og:description' property='og:description' content={description} key='og:description' />

            <meta name='twitter:card' property='twitter:card' content='summary' key='twitter:card' />
            <meta name='twitter:site' property='twitter:site' content='Reku' key='twitter:site' />
            <meta name='twitter:image' property='twitter:image' content={image} key='twitter:image' />
            <meta name='twitter:image:src' property='twitter:image:src' content={image} key='twitter:image:src' />
            <meta name='twitter:title' property='twitter:title' content={title} key='twitter:title' />
            <meta
                name='twitter:description'
                property='twitter:description'
                content={description}
                key='twitter:description'
            />

            <link rel='apple-touch-icon' sizes='57x57' href='https://images.reku.id/favicon/apple-icon-57x57.png' />
            <link rel='apple-touch-icon' sizes='60x60' href='https://images.reku.id/favicon/apple-icon-60x60.png' />
            <link rel='apple-touch-icon' sizes='72x72' href='https://images.reku.id/favicon/apple-icon-72x72.png' />
            <link rel='apple-touch-icon' sizes='76x76' href='https://images.reku.id/favicon/apple-icon-76x76.png' />
            <link rel='apple-touch-icon' sizes='114x114' href='https://images.reku.id/favicon/apple-icon-114x114.png' />
            <link rel='apple-touch-icon' sizes='120x120' href='https://images.reku.id/favicon/apple-icon-120x120.png' />
            <link rel='apple-touch-icon' sizes='144x144' href='https://images.reku.id/favicon/apple-icon-144x144.png' />
            <link rel='apple-touch-icon' sizes='152x152' href='https://images.reku.id/favicon/apple-icon-152x152.png' />
            <link rel='apple-touch-icon' sizes='180x180' href='https://images.reku.id/favicon/apple-icon-180x180.png' />
            <link
                rel='icon'
                type='image/png'
                sizes='192x192'
                href='https://images.reku.id/favicon/android-icon-192x192.png'
            />
            <link rel='icon' type='image/png' sizes='32x32' href='https://images.reku.id/favicon/favicon-32x32.png' />
            <link rel='icon' type='image/png' sizes='96x96' href='https://images.reku.id/favicon/favicon-96x96.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='https://images.reku.id/favicon/favicon-16x16.png' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='msapplication-TileImage' content='https://images.reku.id/favicon/ms-icon-144x144.png' />

            {children}

            <title>{title}</title>

            {renderedHrefLang}
        </NextHead>
    )
}

Head.defaultProps = {
    title: "Harga Bitcoin & Jual Beli Bitcoin Indonesia",
    description:
        "Jual beli Bitcoin Indonesia, Harga Bitcoin, kripto, dan aset kripto lainnya dengan rupiah dan biaya terendah",
    titleSuffix: "",
    image: BRAND_LOGO_URL
}

export default Head
