import tw from "twin.macro"

import Container from "@components/v2/Container"

import Vector1 from "../svg/vector-1.svg"
import Vector2 from "../svg/vector-2.svg"
import Vector3 from "../svg/vector-3.svg"
import Vector4 from "../svg/vector-4.svg"
import Vector5 from "../svg/vector-5.svg"
import VectorBottom from "../svg/vector-bottom.svg"
import VectorTop from "../svg/vector-top.svg"

const StyledContainer = tw(Container)`
    reku-new relative flex flex-col justify-center
    xl:py-[80px] max-sm:px-6 py-8 gap-6
`

const StyledSection = tw.section`
     bg-[#1E4548]
`
const Title = tw.h3`xl:heading-3 heading-6 text-color-bg text-center`
const ListWrapp = tw.div`rounded-xl bg-background xl:p-6 p-3 xl:gap-0 gap-4 xl:grid min-h-[204px] xl:grid-cols-5 flex flex-col xl:z-0 z-10`
const List = tw.div`w-full last:!border-r-0 xl:border-r-[1px] xl:border-b-0 border-b-[1px] xl:pb-0 pb-4 last:border-b-0 flex gap-3 border-[#E7EAEE] xl:h-[116px] xl:pl-2 xl:pr-3 `
const ChecklistText = tw.p`heading-6 paragraph-1 font-bold text-main`
const ChecklistDesc = tw.p`paragraph-3 font-medium  text-[#737271]`
const VectorWrapp = tw.div`absolute `

const FraudulentCheck = () => {
    const Fraudulent = [
        {
            title: "Perizinan dari Regulator",
            desc: "Memiliki perizinan dan patuh terhadap seluruh regulasi yang berlaku",
            vector: <Vector1 />
        },
        {
            title: "Transparansi Transaksi",
            desc: "Rutin melakukan pelaporan transaksi kepada regulator",
            vector: <Vector2 />
        },
        {
            title: "Terlisensi Internasional",
            desc: "Memiliki lisensi manajemen keamanan informasi dari lembaga internasional",
            vector: <Vector3 />
        },
        {
            title: "Proses KYC yang Aman",
            desc: "Menyimpan dan memproses data investor sesuai dengan standar keamanan dan privasi yang ditetapkan",
            vector: <Vector4 />
        },
        {
            title: "Rutin Audit Eksternal",
            desc: "Melakukan audit reguler oleh pihak ketiga independen untuk memastikan kepatuhan dan kualitas exchange",
            vector: <Vector5 />
        }
    ]
    return (
        <StyledSection>
            <StyledContainer>
                <Title>Pilih Platform Investasi Crypto yang Punya Standar Ini</Title>
                <ListWrapp>
                    {Fraudulent.map((c) => (
                        <List>
                            <div className='w-8'>{c.vector}</div>
                            <div>
                                <ChecklistText>{c.title}</ChecklistText>
                                <ChecklistDesc>{c.desc}</ChecklistDesc>
                            </div>
                        </List>
                    ))}
                </ListWrapp>
                <VectorWrapp className='xl:top-[42px] top-[-2px] left-0 xl:left-[-38px]'>
                    <VectorTop />
                </VectorWrapp>
                <VectorWrapp className='xl:bottom-[-2px] bottom-[-20px] right-[-18px] xl:right-[-40px]'>
                    <VectorBottom />
                </VectorWrapp>
            </StyledContainer>
        </StyledSection>
    )
}

export default FraudulentCheck
