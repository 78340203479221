import tw, { styled } from "twin.macro"

import Container from "@components/v2/Container"

import LineScribe from "../svg/line-scibe.svg"

const StyledSection = styled.section`
    ${tw`bg-[url('/next/assets/images/jesse-letter/hero.webp')] xl:h-[818px] h-full `}

    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
`

const StyledContainer = styled(Container)`
    ${tw`reku-new flex h-full flex-col justify-center 
    xl:py-[38px] pt-3 pb-[48px] xl:px-0 px-[8%] `}
`

const Title = tw.div`
    xl:text-[64px] text-[#41403E] xl:max-w-[800px] max-w-[312px] max-sm:max-w-[60%] xl:font-bold xl:leading-[86px] heading-5 mb-3
`
const Text = tw.div`
    xl:text-base text-[#41403E] xl:max-w-[560px] max-w-[80%] text-sm font-normal 
`

const TextWrapper = tw.div``

const Hero = () => {
    return (
        <StyledSection>
            <StyledContainer>
                <TextWrapper>
                    <Title>Kenyamanan Investasi Berawal dari Transparansi</Title>
                    <Text>Kenalan dengan Aset Kripto Secara Lebih Transparan di Sini</Text>
                    <LineScribe className='max-sm:hidden' />
                </TextWrapper>
            </StyledContainer>
        </StyledSection>
    )
}

export default Hero
