import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import JesseLetter from "@app/Microsite/JesseLetter"
import { defaultLanguage } from "@config/i18n"

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale || defaultLanguage, ["components", "common", "pages", "j-letter"]))
    }
})

export default JesseLetter
